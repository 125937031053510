.tooltip-container {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 80px;

	margin: 5px;

	position: fixed;
	bottom: 15px;
	right: 15px;

	z-index: 9999;
	&--hide-desktop {
		display: none;
	}
	&--hide-mobile {
		display: flex;
	}
}

@media only screen and (max-width: 660px) {
	.tooltip-container {
		&--hide-desktop {
			display: flex;
		}
		&--hide-mobile {
			display: none;
		}
	}
}
