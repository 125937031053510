.native-ads-mobile-branding {
	display: none;
}

@media only screen and (max-width: 660px) {
	.native-ads-mobile-branding {
		display: flex;
		justify-content: center;

		width: 100%;
		// height: 150px;
	}
}
